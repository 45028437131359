var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CRow",
        { staticClass: "pb-1" },
        [
          _c(
            "CCol",
            { attrs: { col: "12" } },
            [
              true
                ? _c("BranchCard", {
                    staticClass: "p-0 m-0",
                    attrs: { branchInfo: _vm.branch }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "CCol",
            [
              false
                ? _c(
                    "CCard",
                    [
                      _c("CCardHeader", {
                        attrs: { color: _vm.$theme.backColor }
                      }),
                      _c(
                        "CCardBody",
                        { staticClass: "p-1" },
                        [
                          _c(
                            "CWidgetIcon",
                            {
                              staticClass:
                                "h-100 p-0 m-0 bg-gradient-secondary",
                              staticStyle: { "font-size": "40px" },
                              attrs: {
                                header: "Total Users",
                                text: _vm.totalUsers,
                                color: "info",
                                inverse: "",
                                size: "sm"
                              }
                            },
                            [
                              _c("CIcon", {
                                attrs: { name: "cil-user", height: "36" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CRow",
        { staticClass: "mt-0" },
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                { attrs: { "accent-color": _vm.accentColor } },
                [
                  _c("CCardHeader", { attrs: { color: _vm.backColor } }, [
                    _c(
                      "div",
                      { staticClass: "default-card-header" },
                      [
                        _c("CIcon", { attrs: { name: "cil-user" } }),
                        _vm._v(" Users ")
                      ],
                      1
                    )
                  ]),
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CRow",
                        { staticClass: "justify-content-between" },
                        [
                          _c(
                            "CCol",
                            { attrs: { col: "10" } },
                            [
                              _c("CInput", {
                                ref: "search",
                                staticStyle: { "max-width": "400px" },
                                attrs: {
                                  size: "lg",
                                  placeholder: "Search For a User",
                                  value: _vm.searchValue
                                },
                                on: {
                                  "update:value": function($event) {
                                    _vm.searchValue = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append",
                                    fn: function() {
                                      return [
                                        _c(
                                          "CButton",
                                          {
                                            attrs: { color: "info" },
                                            on: { click: _vm.onSearchClick }
                                          },
                                          [
                                            _c("CIcon", {
                                              attrs: {
                                                name: "cil-magnifying-glass"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c("CCol", { attrs: { col: "1" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "ml-2 align-right float-right btn-group",
                                staticStyle: { "text-align": "right" }
                              },
                              [
                                _c(
                                  "CButton",
                                  {
                                    staticClass: "float-right",
                                    attrs: {
                                      color: "info",
                                      href: _vm.csvCode,
                                      download: _vm.downloadFileName,
                                      target: "_blank"
                                    },
                                    on: { click: _vm.handleClick }
                                  },
                                  [
                                    _c(
                                      "div",
                                      [
                                        _c("FontAwesomeIcon", {
                                          attrs: {
                                            icon: "file-csv",
                                            size: "2x"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            [
                              _c(
                                "CButtonGroup",
                                { staticClass: "align-right float-right" },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "px-1" },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "m-0 p-1",
                                          attrs: {
                                            color: "info",
                                            size: "sm",
                                            square: ""
                                          },
                                          on: { click: _vm.refresh }
                                        },
                                        [
                                          _c("CIcon", {
                                            staticClass: "h-100 m-0 p-0",
                                            attrs: { name: "cil-sync" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "px-1" },
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "m-0 p-1",
                                          attrs: {
                                            color: "info",
                                            size: "sm",
                                            square: ""
                                          },
                                          on: { click: _vm.handleAddClick }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "pr-0" },
                                            [
                                              _c("CIcon", {
                                                staticClass: "h-100 m-0 p-0",
                                                attrs: { name: "cil-plus" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "12" } },
                            [
                              _c("CDataTable", {
                                staticClass: "table w-100",
                                attrs: {
                                  title: "Users",
                                  "table-filter": false,
                                  tableFilterValue: _vm.searchValue,
                                  hover: "",
                                  items: _vm.filteredItems,
                                  fields: _vm.fields,
                                  "items-per-page": 10,
                                  pagination: {
                                    doubleArrows: false,
                                    align: "center"
                                  },
                                  responsive: "",
                                  striped: "",
                                  sorter: "",
                                  "clickable-rows": ""
                                },
                                on: { "row-clicked": _vm.handleRowClicked },
                                scopedSlots: _vm._u([
                                  {
                                    key: "img",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getImage(item)
                                              )
                                            }
                                          })
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "uid",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "table-td-uid table-overflow-elipses"
                                          },
                                          [_vm._v(" " + _vm._s(item.uid) + " ")]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "email",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _c("span", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.getEmail(item)
                                              )
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "table-overflow-elipses"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.getContact(item)) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "contact",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "table-overflow-elipses"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.getContact(item)) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "name",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm.isCurrentUser(item)
                                            ? _c("div", [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.getUserName(item)
                                                    ) + " "
                                                  )
                                                ])
                                              ])
                                            : _c("div", [
                                                _vm._v(
                                                  _vm._s(_vm.getUserName(item))
                                                )
                                              ])
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "role",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "usr-role-table-data",
                                            attrs: { "data-visible": "false" }
                                          },
                                          [
                                            item.role === "admin"
                                              ? _c("strong", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.getRole(item))
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.getRole(item))
                                                  )
                                                ])
                                          ]
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "status",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "CBadge",
                                              {
                                                attrs: {
                                                  color: _vm.getBadgeColor(item)
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(item.status) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "lastActive",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.getLastActive(item)) +
                                              " "
                                          )
                                        ])
                                      ]
                                    }
                                  },
                                  {
                                    key: "options",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "CRow",
                                              { staticClass: "w-25" },
                                              [
                                                _c(
                                                  "CButtonGroup",
                                                  [
                                                    _c(
                                                      "CButton",
                                                      {
                                                        ref: "edit",
                                                        attrs: {
                                                          color: "info",
                                                          variant: "ghost"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewClick(
                                                              item
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" more ")]
                                                    ),
                                                    _vm.isAdmin &&
                                                    _vm.canAdmin(item)
                                                      ? _c(
                                                          "CButton",
                                                          {
                                                            ref: "edit",
                                                            attrs: {
                                                              variant: "ghost",
                                                              color: "danger"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteClick(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("CIcon", {
                                                              attrs: {
                                                                name:
                                                                  "cil-trash"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CModal", {
        staticClass: "p-0 m-0",
        attrs: {
          size: "lg",
          show: _vm.addUserModal,
          title: "Add New User",
          color: "dark",
          centered: ""
        },
        on: {
          "update:show": function($event) {
            _vm.addUserModal = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "header-wrapper",
            fn: function() {
              return [_c("div")]
            },
            proxy: true
          },
          {
            key: "body-wrapper",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("UserAddForm", {
                      ref: "adduser",
                      staticClass: "mb-0 p-0",
                      attrs: {
                        passwordMessage:
                          "Password Must be 8 Characters Long and Contain at least 1 Capital Letter and 1 Lower case"
                      },
                      on: {
                        success: _vm.onSuccess,
                        cancel: function($event) {
                          _vm.addUserModal = false
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer-wrapper",
            fn: function() {
              return [_c("div", { staticClass: "p-0 m-0" })]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "CModal",
        {
          attrs: {
            show: _vm.deleteConfirmModal,
            centered: "",
            title: _vm.deleteTitle,
            color: "danger"
          },
          on: {
            "update:show": function($event) {
              _vm.deleteConfirmModal = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("CButton", { on: { click: _vm.cancel } }, [
                    _vm._v(" Cancel ")
                  ]),
                  _c("CButton", { on: { click: _vm.confirmDelete } }, [
                    _vm._v(" OK ")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.deleteMessage) + " "), _c("CRow")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
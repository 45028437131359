
/** Converts JSON Object to a CSV  */


const convert = (headers,data) =>{
    let tempList = [... data];
    let newList =[]; 
      for (var i = 0; i < tempList.length; i++) {
        let item = tempList[i]; // Object
        
        if (item) {
          //item.site= `"${item.site}"`; 
          newList.push([]);
          for (var key of headers) {
            
            if (item[key] === undefined || item[key] === null) {
              item[key] = ""; // Give it a dummy string
            }
            newList[i].push(`"${item[key].toString()}"`);
          }
        }
      }
      tempList = newList.map(item => item.join(","));
      //tempList = tempList.map(item => Object.values(item).join(","));
      //console.log(tempList);
      // FInally format the headers
      headers = headers.map(item => item.toUpperCase());
      headers = headers.join(","); // Join all the elements together , separated as columns
      //console.log(headers);
      tempList.splice(0, 0, headers);

      tempList = tempList.join("\n");

      return tempList;
}


/** Convert Data  */
export default (headers,data)=>{
    return convert(headers,data); 
}
export{
    convert
}

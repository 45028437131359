<template>
  <div>
    <CRow class="pb-1">
      <CCol col="12">
        <BranchCard v-if="true" class="p-0 m-0" :branchInfo="branch" />
      </CCol>
      <CCol>
        <CCard v-if="false">
          <CCardHeader :color="$theme.backColor"> </CCardHeader>
          <CCardBody class="p-1">
            <CWidgetIcon
              header="Total Users"
              :text="totalUsers"
              color="info"
              inverse
              size="sm"
              style="font-size: 40px"
              class="h-100 p-0 m-0 bg-gradient-secondary"
            >
              <CIcon name="cil-user" height="36" />
            </CWidgetIcon>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    
    <CRow class="mt-0">
      <CCol>
        <CCard :accent-color="accentColor">
          <CCardHeader :color="backColor">
            <div class="default-card-header">
              <CIcon name="cil-user"> </CIcon>
              Users
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow class="justify-content-between">
              <CCol col="10">
                <CInput
                  ref="search"
                  size="lg"
                  style="max-width: 400px"
                  placeholder="Search For a User"
                  :value.sync="searchValue"
                >
                  <template #append>
                    <CButton color="info" @click="onSearchClick">
                      <CIcon name="cil-magnifying-glass" />
                    </CButton>
                  </template>
                </CInput>
              </CCol>
              <CCol col="1">
                <div
                  class="ml-2 align-right float-right btn-group"
                  style="text-align: right"
                >
                  <CButton
                    class="float-right"
                    color="info"
                    :href="csvCode"
                    :download="downloadFileName"
                    target="_blank"
                    @click="handleClick"
                  >
                    <div>
                      <FontAwesomeIcon icon="file-csv" size="2x" />
                      <!--CIcon name="cil-file" />
                            <span class="mt-1" style="color:white ">.csv</span-->
                    </div>
                  </CButton>
                </div>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <CButtonGroup class="align-right float-right">
                  <span class="px-1">
                    <CButton
                      class="m-0 p-1"
                      color="info"
                      size="sm"
                      square
                      @click="refresh"
                    >
                      <CIcon name="cil-sync" class="h-100 m-0 p-0" />
                    </CButton>
                  </span>
                  <span class="px-1">
                    <CButton
                      class="m-0 p-1"
                      color="info"
                      size="sm"
                      square
                      @click="handleAddClick"
                    >
                      <div class="pr-0">
                        <CIcon name="cil-plus" class="h-100 m-0 p-0" />
                      </div>
                    </CButton>
                  </span>
                </CButtonGroup>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <CDataTable
                  class="table w-100"
                  title="Users"
                  :table-filter="false"
                  :tableFilterValue="searchValue"
                  hover
                  :items="filteredItems"
                  :fields="fields"
                  :items-per-page="10"
                  :pagination="{ doubleArrows: false, align: 'center' }"
                  responsive
                  striped
                  sorter
                  clickable-rows
                  @row-clicked="handleRowClicked"
                >
                  <template #img="{ item }">
                    <td>
                      <div v-html="getImage(item)"></div>
                    </td>
                  </template>

                  <template #uid="{ item }">
                    <td class="table-td-uid table-overflow-elipses">
                      {{ item.uid }}
                    </td>
                  </template>
                  <template #email="{ item }">
                    <td>
                      <span v-html="getEmail(item)"></span>
                      <div class="table-overflow-elipses">
                        {{ getContact(item) }}
                      </div>
                    </td>
                  </template>
                  <template #contact="{ item }">
                    <td class="table-overflow-elipses">
                      {{ getContact(item) }}
                    </td>
                  </template>

                  <template #name="{ item }">
                    <td>
                      <div v-if="isCurrentUser(item)">
                        <strong>{{ getUserName(item) }} </strong>
                      </div>
                      <div v-else>{{ getUserName(item) }}</div>
                    </td>
                  </template>

                  <template #role="{ item }">
                    <td data-visible="false" class="usr-role-table-data">
                      <strong v-if="item.role === 'admin'">
                        {{ getRole(item) }}</strong
                      >
                      <span v-else> {{ getRole(item) }}</span>
                    </td>
                  </template>
                  <template #status="{ item }">
                    <td>
                      <CBadge :color="getBadgeColor(item)">
                        {{ item.status }}
                      </CBadge>
                    </td>
                  </template>
                  <template #lastActive="{ item }">
                    <td>
                      {{ getLastActive(item) }}
                    </td>
                  </template>
                  <template #options="{ item }">
                    <td>
                      <CRow class="w-25">
                        <CButtonGroup>
                          <CButton
                            ref="edit"
                            color="info"
                            variant="ghost"
                            @click="viewClick(item)"
                          >
                            <!--FontAwesomeIcon :icon="['fas', 'eye']" /-->
                            more
                          </CButton>

                          <CButton
                            v-if="isAdmin && canAdmin(item)"
                            ref="edit"
                            variant="ghost"
                            color="danger"
                            @click="deleteClick(item)"
                          >
                            <CIcon name="cil-trash" />
                          </CButton>
                        </CButtonGroup>
                      </CRow>
                    </td>
                  </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      size="lg"
      class="p-0 m-0"
      :show.sync="addUserModal"
      title="Add New User"
      color="dark"
      centered
    >
      <template #header-wrapper>
        <div></div>
      </template>
      <template #body-wrapper>
        <div>
          <UserAddForm
            ref="adduser"
            class="mb-0 p-0"
            passwordMessage="Password Must be 8 Characters Long and Contain at least 1 Capital Letter and 1 Lower case"
            @success="onSuccess"
            @cancel="addUserModal = false"
          />
        </div>
      </template>

      <template #footer-wrapper>
        <div class="p-0 m-0"></div>
      </template>
    </CModal>

    <CModal
      :show.sync="deleteConfirmModal"
      centered
      :title="deleteTitle"
      color="danger"
    >
      {{ deleteMessage }}

      <CRow> </CRow>
      <template #footer>
        <CButton @click="cancel"> Cancel </CButton>
        <CButton @click="confirmDelete"> OK </CButton>
      </template></CModal
    >
  </div>
</template>
    </CModal>
  </div>
</template>

<style scoped>
.table-td-uid {
  max-width: 120px;
}
.table-overflow-elipses {
  table-layout: fixed;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.table {
  font-size: 16px;
}
.table td {
  margin: 10px auto;
  padding-bottom: 10px;
  padding-top: 10px;
}
.table-hidden {
  display: none;
  visibility: hidden;
}
.usr-role-table-data {
  text-transform: capitalize;
}
</style>


<script>
/** Creates a Tabular List of Users in the Clients System 
 From this view they can : 
 - View Name and contact Info 
 - Search By User Name 
 - View their Role
 - View their Status 

 - Tools -> View / Edit / Delete 

 Actions: Table includes a Filter Options to Filter By Users 
 Or Roles 

 Actions: Download Users as .CSV 
    -> Export Functionality -> Inherit from CTableWith Download?

    -> Add new user 
        - Ability to add a new user to the list 

    -> Sort By: 
        - User Role 
        - User User Status. 

 */

//import { users } from "./demoData.js";

// Default Fields to create the table
const defualtFields = () => {
  return [
    { key: "img", label: "" },
    // { key: "uid", label: "User ID" }, // Hidden Field

    { key: "name", label: "Name" },
    { key: "email", label: "Contact" },
    //{ key: "contact", label: "Contact" },
    {
      key: "role",
      label: "Role",
      _classes: ["table-hidden", "w-0"],
      sorter: true,
      _style: { width: "1px", display: "none", visibility: "hidden" },
    },
    { key: "status", label: "Status" },
    { key: "lastActive", label: "Last Login" },
    { key: "options", label: "Options" },
  ];
};

import UserAddForm from "./UserAddForm";

import BranchCard from "@/components/branches/BranchCard";
import DashboardCard from "@/components/dashboard/DashboardCard";
import SingleDisplayWidget from "@/widgets/SingleDisplayWidget";
import csvHelper from "../../../lib/csvHelper";
import ThemeMixin from "@/mixins/ThemeMixin"; 

export default {
  name: "UserListView",
  components: {
    UserAddForm,
    BranchCard,
    DashboardCard,
    SingleDisplayWidget,
  },
  props: {
    //users: Array, // User Must Supply List of Users
    fields: {
      // User Also must supply Array of fields to display
      type: Array,
      default: defualtFields, // If not supplied , we will provid defualts
    },
  },
  data() {
    return {
      searchValue: "", // The Filter value typed by the user
      downloadFileName: "users.csv",
      deleteConfirmModal: false,
      deleteMessage: "",
      deleteTitle: "Confirm Delete",
      selected: null,

      addUserModal: false,
    };
  },
  mixins:[ThemeMixin], 
  computed: {
    
    branch() {
      let branch_id = this.$store.getters.selectedBranch
        ? this.$store.getters.selectedBranch.branch_id
        : "1";

      return this.$store.getters.selectedBranch;
      if (
        branch_id &&
        this.$store.getters.branches &&
        this.$store.getters.branches.length > 0
      ) {
        return this.$store.getters.branches.find(
          (item) => item.branch_id === branch_id
        );
      }
    },
    branchName() {
      if (this.branch) {
        return this.branch.name;
      }
    },
    totalUsers() {
      return 0; //return this.filteredItems ? this.filteredItems.length : 0;
    },
    users() {
      if (this.$store.getters.users) {
        let branch_id = this.branch.branch_id;
        return this.$store.getters.users.filter(
          (user) => user.branch_id === branch_id
        );
      }
    },
    /** Returns the filter user List Connent based on filter calue  */
    filteredItems() {
      //return this.users;

      if (!this.users) return [];
      let tempList = [...this.users];

      // Sort by Admins and filter by you to ensure that you are always at the top of this list
      tempList = tempList.sort((a, b) => {
        if (this.isCurrentUser(a)) {
          return -1;
        } else if (b.uid == this.$auth.user_id) {
          return 1;
        }
        if (a.role === "admin" && b.role != a.role) {
          return -1;
        } else {
          return 1;
        }
      });

      // Finally Append Table Classes to each user here
      for (var user of tempList) {
        if (this.isCurrentUser(user)) {
          user._classes = "table-info";
        } else if (user.status && user.status.toLowerCase() === "blocked") {
          //user._classes="table-danger"
        }
      }
      /*
      if (!this.users) {
        tempList = users;
      }
      */
      // TODO: apply filter here.
      // Sort by all active, with active first and inactive last
      return tempList;
    },
    isAdmin() {
      return this.$auth.isAdmin();
    },
    csvHeaders() {
      try {
        var headers = this.fields.map((item) => {
          if (item.label) return item.label;
          else if (item.key) {
            return item.key;
          } else return item;
        });
        // TODO: Replace Object Keys with the Displayed Column Labels??
        if (this.filteredItems) {
          headers = Object.keys(this.filteredItems[0]);
          headers = headers.map((item) => item.toUpperCase());
        }
        return headers;
      } catch (err) {}
    },
    csvContent() {
      if (!this.filteredItems || !this.csvHeaders) {
        return;
      }
      var tempList = [];
      var headers = this.csvHeaders;
      //return csvHelper(headers, this.filteredItems);

      tempList = this.filteredItems.map((item) =>
        Object.values(item).join(",")
      );
      tempList.splice(0, 0, headers.join(","));
      tempList = tempList.join("\n");

      return tempList;
    },
    csvCode() {
      return (
        //"data:text/csv;charset=utf-8,SEP=,%0A"
        "data:text/csv;charset=utf-8," + encodeURIComponent(this.csvContent)
      );
    },
  },
  watch:{
    branch(){
      this.$nextTick(async ()=>{
        await this.refresh(); 
      }); 
    }
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    canAdmin(selectedUser) {
      if (!selectedUser) {
        return false;
      }
      if (this.isAdmin && this.$auth.user_id !== selectedUser.uid) {
        return true;
      }
      return false;
    },
    async refresh() {
      try {
        let res = await this.$app.loadUsers();
        let branch_id = this.branch.branch_id;
        /*
      this.users = res;
      this.users = this.users.filter(
        (item) => item.branch_id === branch_id
      );
      */
      } catch (err) {
        console.log(err);
      }
    },

    async getUsers() {
      let tempList;
      if (!this.users) {
        await this.refresh();

        tempList = this.users;
      } else tempList = this.users;
      console.log(this.users);
      return tempList;
    },
    onSuccess(e) {
      this.addUserModal = false;
      this.refresh();
    },
    handleClick(event) {},
    handleAddClick(event) {
      // TODO: Navigate to Add user form
      this.addUserModal = true;
      this.$refs.adduser.load();
      //this.$router.push("/users/add");
    },
    handleRowClicked(item, index) {
      console.log("You Clicked Row " + index, item);
      this.showUser(item);
    },
    onSearchClick(item) {
      this.searchValue = this.searchValue;
    },

    viewClick(item) {
      console.log("View ", item);

      // TODO: Cancel propogation of event
      this.showUser(item);
      event.stopPropagation();
    },
    deleteClick(item) {
      if (item.uid === this.$auth.user_id) {
        return;
      }
      //this.deleteTitle="Confirm Delete User " + item.uid;
      //this.deleteMessage = "Swipe to remove " + this.getUserName(item);
      this.deleteMessage = "Delete User Account for " + this.getUserName(item);

      this.selected = item;
      this.deleteConfirmModal = true;
      //alert("Delete User" + item.uid);
      event.stopPropagation();
    },
    showUser(item, index) {
      let uid = item.uid;
      let id = item.id;

      let path = `/users/${id}`;
      console.log(path);
      this.$router.push({ path: path });
    },
    cancel(e) {
      this.deleteConfirmModal = false;
    },
    confirmDelete(item) {
      try {
        let user = this.selected;
        this.deleteUser(user);
      } catch (err) {
        console(err);
      } finally {
        this.deleteConfirmModal = false;
      }
    },
    async deleteUser(item) {
      try {
        console.log("Deleting user ", item);
        let res = await this.$api.deleteUser(item.uid);
        if (res.status == 200) {
          console.log(res);
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.refresh();
      }
    },

    isCurrentUser(item) {
      return item.uid == this.$auth.user_id;
    },
    getUserName(item) {
      if (!item) return "";
      return `${item.first?item.first:""} ${item.last?item.last:""}`;
    },
    getImage(item) {
      if (item && item.img) {
        return `<img class="rounded-circle" width="50px" height="100%" alt="100x100" src="${item.img}"
          data-holder-rendered="true">`;
      }

      return `<img class="rounded-circle" width="50px" height="100%" alt="100x100" src="https://s.gravatar.com/avatar/fd14f83a37594373470f1c9fdb18ad08?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fkh.png"
          data-holder-rendered="true">`;
    },
    getBadgeColor(item) {
      if (!item || !item.status) {
        return "";
      }
      switch (item.status.toLowerCase()) {
        case "active":
          return "success";
          break;
        case "pending":
        case "inactive":
          return "warning";
          break;
        default:
          return "danger";
      }
    },
    getLastActive(item) {
      if (item && item.lastActive) {
        let now = Date.now();
        let date = new Date(item.lastActive);

        return `${this.$moment.duration(now - date).humanize()} ago`;
      }
      return "never";
    },
    /** Returns an HTML Ref Link to users email  */
    getEmail(item) {
      if (!item || !item.email) return "";

      return `<a href="mailto:${item.email}" target="_blank">${item.email} </a>`;
    },
    getContact(item) {
      if (!item || !item.phone_number) return "";
      return item.phone_number; // T
    },
    getRole(item) {
      // TODO: Create a Library function to extract the role "Nickname"
      if (!item || !item.role) return "unknown";

      return this.$app.getUserRole(item.role);
      
    },
  },
};
</script>